<template>
	<edit-template class="box" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <el-form ref="form" :model="form" :rules="rules" class="form">
      <div class="title "></div>
      <el-form-item label="测评名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="应用角色" prop="role">
        <el-radio v-model="form.role" @change="radioChange(1)" label="1">学生</el-radio>
        <el-radio v-model="form.role" @change="radioChange(2)" label="2">仅班主任</el-radio>
      </el-form-item>
      <el-form-item label="测试范围" prop="range">
        <el-checkbox-group v-show="false" v-model="form.range">
        </el-checkbox-group>
        <el-popover :popper-class="'pop-class'" :append-to-body="false" placement="right" trigger="click" width="400">
          <el-tree :style="{
               maxHeight: '60vh',
               overflowY: 'auto'
              }" ref="tree" :data="testRangeData" :default-checked-keys="rangeShow" :default-expanded-keys="rangeShow" :props="defaultProps" :check-on-click-node="true" node-key="unique_id" show-checkbox="" @check="treeChange">
          </el-tree>
          <el-button slot="reference">点击选择</el-button>
        </el-popover>
        <div style="padding-left: 152rem;line-height: 30rem;padding-top: 10rem">
          <span v-for="(item,index) in rangeStr" style="word-break: keep-all;display: inline-block">{{ item }}{{
              index === rangeStr.length - 1 ? '' : '，'
            }}</span>
        </div>
      </el-form-item>
      <el-form-item label="选择问卷" prop="paper_id">
        <el-select v-model="form.paper_id" placeholder="请选择">
          <el-option v-for="item in paperOptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择开始时间" prop="start_at">
        <el-date-picker v-model="startYMD" :default-value="new Date()" :editable="false" :picker-options="{
              disabledDate: (date) =>{
                return date.getTime() < Date.now() - 86400000
              }
            }" format="yyyy-MM-dd" placeholder="请选择" type="date" value-format="yyyy-MM-dd" @change="checkStart">
        </el-date-picker>
        <el-time-select v-model="startHM" :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }" placeholder="请选择" style="margin-left: 24rem" @change="checkStart">
        </el-time-select>
      </el-form-item>
      <el-form-item label="选择结束时间" prop="end_at">
        <el-date-picker v-model="endYMD" :default-value="new Date()" :disabled="!isStartChecked" :editable="false" :picker-options="endPickerOptions" format="yyyy-MM-dd" placeholder="请选择" type="date" value-format="yyyy-MM-dd" @change="checkEnd">
        </el-date-picker>
        <el-time-select v-model="endHM" :disabled="!isStartChecked" :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }" placeholder="请选择" style="margin-left: 24rem" @change="checkEnd">
        </el-time-select>
      </el-form-item>
      <el-form-item v-if="versionShow" label="学生端展示最低版本" prop="version_id">
        <el-select v-model="form.version_id" placeholder="请选择">
          <el-option v-for="item in versionOptions" :key="item.id" :label="item.code" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item ref="img-box" label="展示图片" prop="image" :error="errorMessage">
        <el-input v-show="false" v-model="form.image"></el-input>
        <el-upload :before-upload="imgUploadBefore" :class="isUploaded ? 'is-uploaded' : ''" :headers="headers" :http-request="uploadImg" :limit="1" :file-list="fileList" :multiple="false" :on-preview="imgUploadPreview" :on-remove="imgUploadRemove" action="http://www.baidu.com" list-type="picture-card" name="image">
          <i class="el-icon-plus"></i>
        </el-upload>
        <input type="file" ref="imgInput" v-show="false" @change="replaceImg">

        <el-dialog :visible.sync="dialogVisible">
          <img :src="dialogImageUrl" alt="" width="100%">
        </el-dialog>
        <div v-show="isUploaded" class="replace-img" @click="$refs.imgInput.click()">替换图片</div>
        <p style="padding-left: 152rem;color: #999">（请上传小于5M的png/jpg格式的图片，图片尺寸推荐使用1100*276）</p>
      </el-form-item>
    </el-form>

    
  
</edit-template>
</template>
<script>
import moment from 'moment'


export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    //检查结束时间是否大于开始时间
    let validatePass1 = (rule, value, callback) => {
      let startStr = this.startYMD + ' ' + this.startHM,
          endStr = this.endYMD + ' ' + this.endHM;
      let timeIsTrue = moment(startStr).format('x') < moment(endStr).format('x')
      if (!timeIsTrue) {
        callback(new Error('请仔细检查日期选项'));
      } else {
        callback()
      }
    };
    return {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        'authorization': 'Bearer ' + sessionStorage.getItem('TOKEN')
      },

      form: {
        name: null,
        role: '1',
        range: [],
        paper_id: '',
        start_at: '',
        end_at: '',
        version_id: '',
        image: ''
      },

      rangeShow: [],
      rangeStr: [],

      //测试范围相关数据
      testRangeData: [],  //测试范围的选项配置，树形结构
      defaultProps: {
        children: 'children',
        label: 'label'
      },  //测试范围配置默认键名，label用于展示，

      //选择问卷选项配置
      paperOptions: [],

      //选择时间相关数据
      isStartChecked: false,  //开始时间是否已选
      endPickerOptions: {
        disabledDate: (date) => {
          return date.getTime() < moment(this.startYMD).format('x')
        }
      },
      startYMD: '',  //开始时间：年月日
      startHM: '',  //开始时间：时分
      endYMD: '',  //结束时间：年月日
      endHM: '',  //结束时间：时分

      //学生端最低版本选项配置
      versionOptions: [],
      versionShow: true,

      //上传图片相关
      isUploaded: false,
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      errorMessage: '',

      rules: {
        name: [{required: true, message: "请输入测评名称"}, {
          pattern: /^[\u4e00-\u9fa5\w]{1,30}$/,
          message: "请输入30字以内的汉字，数字和字母"
        }],
        role: [{required: true, message: "请选择应用角色"}],
        range: [{required: true, message: "请选择测试范围"}],
        paper_id: [{required: true, message: "请选择问卷"}],
        start_at: [{required: true, message: "请选择开始时间"}],
        end_at: [{required: true, message: "请选择结束时间"}, {validator: validatePass1}],
        version_id: [{required: true, message: "请选择学生端展示最低版本"}],
        image: [{required: true, message: "请上传展示图片"}],
      },
    }
  },
  created() {
    this.getPaper(1)
    this.getRange()
    this.getVersion()
  },

  methods: {
    //获取问卷配置
    getPaper(value) {
      this.$_axios2('/api/evaluation/index/paper?type=' + value).then(res => {
        if (res.data.status === 0) {
          this.paperOptions = res.data.data
        }
      })
    },

    //获取测评范围配置
    getRange() {
      this.$_axios2('/api/evaluation/index/range').then(res => {
        // console.log(res)
        if (res.data.status === 0) {
          this.testRangeData = res.data.data
        }
      })
    },

    getVersion() {
      this.$_axios2('/api/evaluation/index/version').then(res => {
        if (res.data.status === 0) {
          this.versionOptions = res.data.data
        }
      })
    },

    //应用角色改变
    radioChange(value){
      this.form.paper_id = ''
      this.getPaper(value)
      this.versionShow = value === 1
    },

    //测评范围改变
    treeChange(node, data) {
      // console.log(node)
      // console.log(data)
      // console.log(this.$refs.tree.getCheckedNodes(false,true))
      let list = JSON.parse(JSON.stringify(this.$refs.tree.getCheckedNodes(false,true)))
      list.forEach(item=>{
        if(item.children && item.children.length){
          delete item.children
        }
      })
      // console.log(list)

      this.form.range = []
      this.rangeShow = []
      this.rangeStr = []
      if (data.checkedNodes.length) {
        data.checkedNodes.forEach(item => {
          if (!item.children) {
            this.form.range.push(item.id)
            this.rangeStr.push(item.unique_id)
          }
        })
        this.rangeShow = data.checkedKeys
      }
    },

    //检测开始时间是否选中完毕
    checkStart() {
      this.endYMD = ''
      this.endHM = ''
      this.form.end_at = ''
      let boo = this.startYMD && this.startHM
      this.isStartChecked = boo
      this.form.start_at = boo ? (this.startYMD + ' ' + this.startHM) : ''
    },

    //检测结束时间是否选中完毕
    checkEnd() {
      let endYMD = this.endYMD,
          endHM = this.endHM;
      this.form.end_at = (endYMD && endHM) ? (endYMD + ' ' + endHM) : ''
    },

    //图片上传前的回调
    imgUploadBefore(file) {
      // console.log(file)
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message({
          type: 'error',
          message: '上传图片只能是 jpg/png 格式!',
          duration: 2000
        })
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '上传图片大小不能超过 5MB!',
          duration: 2000
        })
      }
      return isJPG && isLt2M;
    },

    //删除图片回调
    imgUploadRemove() {
      this.isUploaded = false
      this.form.image = ''
      this.errorMessage = '请上传展示图片'
    },

    //点击图片时的回调
    imgUploadPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    uploadImg(file) {
      let boo = this.imgUploadBefore(file.file)
      if (boo) {
        const loading = this.$loading({
          lock: true,
          text: '上传中',
          customClass: 'img-upload-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$uploadToOSS(file.file).then(res => {
          this.$refs['img-box'].clearValidate()
          this.form.image = res.split('/').slice(-1).join()
          this.isUploaded = true
          this.fileList = [{
            name: file.filename,
            url: res
          }]
        }).finally(() => {
          loading.close()
        })
      }
    },

    replaceImg(e){
      this.uploadImg({file:e.target.files[0]})
    },


    submit() {
      this.$refs.form.validate().then(res => {
        let params = {...this.form,}
        params.version_id = this.versionShow ? params.version_id : 0
        // console.log(params)
        this.$_axios2.post('/api/evaluation/index', params).then(res => {
          // console.log(res)
          if (res.data.status === 0) {
            this.$message({
              message: '保存成功！',
              type: 'success',
              onClose: () => {
                this.$router.back()
              }
            })
          }
        })
      }).catch(err => {
        // this.$message.warning("请输入完整的测评信息")
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.pop-class{
  //z-index: 2001 !important;
}
//.el-popper{
//  z-index: 9999 !important;
//}

::v-deep .el-form-item__label {
  width: 150rem;
}

::v-deep .is-uploaded .el-upload {
  display: none;
}

.box {
  .form {
    min-height: calc(100vh - 220rem);
    font-size: 14rem;
    margin: 0 0 20rem 50rem;

    ::v-deep .el-form-item__error {
      margin-left: 80rem;
    }

    .title {
      font-weight: bold;
      font-size: 16rem;
      padding-top: 35rem;
      margin-bottom: 35rem;
    }

    .el-form-item {
      margin-left: 50rem;
    }

    .subject {
      display: flex;
      align-items: center;

      ::v-deep .el-form-item__error {
        margin-left: 0;
      }

      //padding: 15rem 0rem;
      //justify-content: space-around;
      margin-bottom: 15rem;

      .item_box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15rem 10rem;
        flex: 1;
      }

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10rem;

        > span {
          margin-right: 15rem;
        }

        .btn {
          border-radius: 50%;
          width: 30rem;
          height: 30rem;
          text-align: center;
          line-height: 30rem;
          cursor: pointer;
          margin-left: 15rem;
          border: 1rem solid #666666;
        }
      }

      .item.btns {
        width: 100rem;
        justify-content: flex-start;
        text-align: left;

        .btn {
          display: inline-block;
        }
      }

      .el-form-item {
        margin: 0;
      }

    }

    @media only screen and (max-width: 1399rem) {
      .subject {
        .item {
          display: block;
          text-align: center;

          > span {
            display: block;
            margin-right: 0;
            margin-bottom: 15rem;
          }

          ::v-deep .el-input {
            width: 180rem !important;
          }
        }

        .btns {
          width: 130rem;
          justify-content: flex-start;
          text-align: left;

          .btn {
            display: inline-block;
          }
        }
      }
    }
  }
}

.replace-img{
  width: 100rem;
  margin-left: 160rem;
  border: 1rem solid #999999;
  color: #666;
  border-radius: 6rem;
  height: 30rem;
  line-height: 30rem;
  text-align: center;
  cursor: pointer;
}
</style>
